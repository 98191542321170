<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="8">
                <h2>AffaldPlus</h2>
                {{ CompanyAddress }}
                <br>
                {{ CompanyCity }}
                <br>
                <a>
                    faktura@affaldplus.dk
                </a>
                <br>
                Tlf: {{ CompanyPhoneNumber }}
                <br>
                Cvr: {{ CompanyCvr }}
                <br>
                <a href="https://affaldplus.dk/kunde-paa-genbrugsplads" >
                    www.affaldplus.dk
                </a>
            </v-col>
            <v-col cols="12" md="8">
                <b>Telefontider</b>
                <br>
                Mandag - torsdag: 8.00 - 15:30
                <br>
                Fredag: 8.00 - 14.00
                <b />
                <br><br>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
        }
    },
    computed: {
        CompanyName() {
            return process.env.VUE_APP_TERMS_COMPANY_NAME
        },
        CompanyAddress() {
            return process.env.VUE_APP_TERMS_COMPANY_ADDRESS
        },
        CompanyCity() {
            return process.env.VUE_APP_TERMS_COMPANY_ZIPCODE_AND_CITY
        },
        CompanyCvr() {
            return process.env.VUE_APP_TERMS_COMPANY_CVR
        },
        CompanyPhoneNumber() {
            return process.env.VUE_APP_TERMS_COMPANY_PHONE_NUMBER
        },
    },
    created() {
    },
    methods: {
    }
}
</script>